$(function () {
  var freepik = {
    init: function () {
      this.placeholder();
      this.attach();
    },

    placeholder: function () {
      if ($(".freepik-placeholder").length > 0) return;
      if ($(".icon-grid").length === 0) return;

      var icons = $(".icon-grid .icon-preview");
      var icons_per_row = Math.floor(
        $(".icon-grid").width() / $(".icon-preview").width()
      );
      var visible_grid_area = $(window).height() - $(".icon-grid").offset().top;
      var last_visible_row = Math.floor(
        visible_grid_area / $(".icon-preview").height()
      );
      var last_full_row = Math.floor(icons.length / icons_per_row);
      var minimum_icons_rows = 6;

      // Ensure minimum rows of icons before the banner
      if (last_visible_row < minimum_icons_rows) {
        last_visible_row = minimum_icons_rows;
      }

      // Insert after the last full row of icons
      if (last_visible_row > last_full_row) {
        last_visible_row = last_full_row;
      } else {
        // Ensure the placeholder is within the visible area
        last_visible_row--;
      }

      var index = icons_per_row * last_visible_row;

      var html = $("<div />", {
        class: "freepik-placeholder d-block",
        "data-phrase":
          $(".suggestions .new_query strong").text() ||
          $("[data-phrase]").data("phrase") ||
          $.url().param("q") ||
          "",
      });

      if (index > icons.length) {
        index = icons.length;
      }

      var inserted_ads = 0;

      for (var i = 0; i < icons.length; i++) {
        if (i % icons_per_row === 0 && i != 0 && inserted_ads < 6) {
          if (i >= index && i % index === 0) {
            icons.eq(i - 1).after(html.clone());
            inserted_ads++;
          }
        }
      }
    },

    in_view: function () {
      if ($(".freepik-placeholder").length === 0) return false;

      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      var offset = $(".freepik-placeholder").offset().top;

      return windowHeight + scroll >= offset;
    },

    attach: function () {
      if ($(".freepik-placeholder").length === 0) return;

      if (freepik.in_view()) {
        freepik.getMockData();
      } else {
        this.defer();
      }
    },

    getMockData: function () {
      if(topIconsMock.images[0].thumbnail) {
        freepik.renderMock(topIconsMock, peopleStickersMock, natureIconsMock);
      } else {
        topIconsMock.images = $.map(topIconsMock.images, function (image) {
          return {
            thumbnail: image.display_uri,
            link: image.referral_destination,
            viewMoreUrl: topIconsMock.viewMoreUrl,
          };
        });
        peopleStickersMock.images = $.map(peopleStickersMock.images, function (image) {
          return {
            thumbnail: image.display_uri,
            link: image.referral_destination,
            viewMoreUrl: peopleStickersMock.viewMoreUrl,
          };
        });
        natureIconsMock.images = $.map(natureIconsMock.images, function (image) {
          return {
            thumbnail: image.display_uri,
            link: image.referral_destination,
            viewMoreUrl: natureIconsMock.viewMoreUrl,
          };
        });
        freepik.renderMock(topIconsMock, peopleStickersMock, natureIconsMock);
      }
    },

    defer: function () {
      if ($(".freepik-placeholder").length === 0) return;
      $(window).on("scroll.freepik", function () {
        if (freepik.in_view()) {
          freepik.getMockData();
          $(window).off("scroll.freepik");
        }
      });
    },

    renderMock: function (topIconsMock, peopleStickersMock, natureIconsMock) {
      var banner = "ads/_freepik.html";
      $(".freepik-placeholder").html(templates.render(banner, topIconsMock));
      $(".freepik-placeholder-2").html(templates.render(banner, peopleStickersMock));
      $(".freepik-placeholder-3").html(templates.render(banner, natureIconsMock));
      // Activate tooltips within the banner
      $('.freepik-banner [data-toggle="tooltip"]').tooltip();
    },
  };

  freepik.init();

  // Reattach the banner when updating the search results
  $(document).on("search.results", function () {
    freepik.init();
  });
});
